import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { AuthService } from 'app/services/auth.service';
import { DatabaseService } from 'app/services/database.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model';
import { Subject, takeUntil } from 'rxjs';
import { MyNavigationService } from 'app/services/mynavigation.service';
import { SubscriptionType } from 'app/models/subscription.model';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        NotificationsComponent,
        UserComponent,
        RouterOutlet,
        FuseVerticalNavigationComponent,
        FuseFullscreenComponent,
        TranslocoModule
    ],
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isApple = false;

    windowTitle="";

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private cd : ChangeDetectorRef,
        private _authService: AuthService,
        private _databaseService : DatabaseService,
        private _userService: UserService,
        private _navService:MyNavigationService,
  
    ) {}


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
       // Subscribe to navigation data
       this._navigationService.navigation$
       .pipe(takeUntil(this._unsubscribeAll))
       .subscribe((navigation: Navigation) => {
           this.navigation = navigation;

       });

       // Subscribe to the user service
       /*this._userService.user$
       .pipe((takeUntil(this._unsubscribeAll)))
       .subscribe((user: User) => {
           this.user = user;
       });*/
       this.user = this._userService.getUser();
       this._databaseService.userSubject.subscribe((user: User)=>{
           this.user = user;
           this.cd.markForCheck();
       });
       this._databaseService.initDatabase();

       // Subscribe to media changes
       this._fuseMediaWatcherService.onMediaChange$
       .pipe(takeUntil(this._unsubscribeAll))
       .subscribe(({matchingAliases}) => {

           // Check if the screen is small
           this.isScreenSmall = !matchingAliases.includes('md');
       });
       this._navigationService.get();

       this._authService.getProvider().providerData.forEach((p)=>{
           if(p.providerId === 'apple.com'){
               this.isApple = true;
           }
       });

       this._navService.title.subscribe((title)=>{
            this.windowTitle = title;
         });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    notProUser(){
        if(this._databaseService.user!==undefined)
            return this._databaseService.user.Stripe.Type !== SubscriptionType.Pro;
        return false;
    }

    goToAbo(){
        this._router.navigate(['/pages/pricing']);
    }
}
