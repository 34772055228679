<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-accent print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="mb-4 mt-3 flex h-20 items-center justify-center">
            <img
                class="w-10"
                src="assets/images/logos/logo_2couleurs.svg"
                alt="Logo image"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col" *transloco="let t">
    <!-- Header -->
    <div
        class="bg-accent relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon>menu</mat-icon>
        </button>

        <!-- Title -->
        <div>
            <h2
                class="text-3xl relative text-on-accent font-extrabold tracking-tight leading-7 sm:leading-10 truncate"
            >
                {{windowTitle}}
            </h2>
        </div>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
             <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            @if (notProUser()) {
            <button mat-raised-button color="primary" (click)="goToAbo()">
                {{t("updateaccount")}}
            </button>
            }
        </div>
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <!--   <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium"
            >Fuse &copy; {{ currentYear }}</span
        >
    </div>-->
</div>
