<div id="tutorial" *transloco="let t" class="w-screen h-screen flex flex-col justify-center items-center overflow-hidden" [ngClass]="{'bg-black/80': shadow}">

  <div id="tuto-parent" class="inset-0 absolute pointer-events-none z-0 overflow-hidden">

  </div>

  <!--Popup to ask if we gonna show the tutorial-->
  @if (currentStep===-1) {
    <div class="tutorial-popup bg-card rounded-xl p-5">
      <div class="tutorial-popup-content flex flex-col gap-4">
        <div class="flex flex-row gap-4 w-full justify-center items-center">
          <img src="/assets/images/logos/logo_2couleurs.svg" class="h-48 w-auto">
          <div class="tutorial-popup-content flex flex-col gap-4">
            <div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">{{t("TUTO.1")}}</div>
            <div class="flex flex-row gap-4 w-full justify-end items-center">
              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="checked">{{t("TUTO.2")}}</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
          <button mat-raised-button class="btn btn-secondary" (click)="close(false)">{{t("GEN.NO")}}</button>
          <button mat-raised-button color="primary" class="btn btn-primary" (click)="close(true)">{{t("GEN.YES")}}</button>
        </div>
      </div>
    </div>
  }
  <!---->
  @if (currentStep===0) {
    <div class="tutorial-popup bg-card rounded-xl p-5 z-10 tutorial.step.1">
      <div class="tutorial-popup-content flex flex-col gap-4 relative justify-center items-center">
      <!--div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">Création</div-->
      <div class="text-xl tracking-tight leading-tight text-center p-2">{{t("TUTO.3")}}</div>
      <!--button mat-icon-button class="btn btn-icon absolute top-0 right-0" (click)="close(false)">
      <mat-icon>close</mat-icon>
    </button-->
    <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
      <button mat-raised-button color="" class="w-min"(click)="close(false)">
        {{t("fermer")}}
      </button>
      <button mat-raised-button color="primary" class="w-min"(click)="nextStep()">
        {{t("suivant")}}
      </button>
    </div>
  </div>
</div>
}

@if (currentStep===1) {
  <div class="tutorial-popup bg-card rounded-xl p-5 z-10">
    <div class="tutorial-popup-content flex flex-col gap-4 relative justify-center items-center">
    <!--div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">Diffusion</div-->
    <div class="text-xl tracking-tight leading-tight text-center p-2">{{t("TUTO.4")}}</div>
    <!--button mat-icon-button class="btn btn-icon absolute top-0 right-0" (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button-->
  <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
    <button mat-raised-button color="" class="w-min"(click)="close(false)">
      {{t("fermer")}}
    </button>
    <button mat-raised-button color="primary" class="w-min"(click)="nextStep()">
      {{t("suivant")}}
    </button>
  </div>
</div>
</div>
}

@if (currentStep===2) {
  <div class="tutorial-popup bg-card rounded-xl p-5 z-10">
    <div class="tutorial-popup-content flex flex-col gap-4 relative justify-center items-center">
    <!--div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">Mes box</div-->
    <div class="text-xl tracking-tight leading-tight text-center p-2">{{t("TUTO.5")}}</div>
    <!--button mat-icon-button class="btn btn-icon absolute top-0 right-0" (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button-->
  <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
    <button mat-raised-button color="" class="w-min"(click)="close(false)">
      {{t("fermer")}}
    </button>
    <button mat-raised-button color="primary" class="w-min"(click)="nextStep()">
      {{t("suivant")}}
    </button>
  </div>
</div>
</div>
}

@if (currentStep===3) {
  <div class="tutorial-popup bg-card rounded-xl p-5 z-10">
    <div class="tutorial-popup-content flex flex-col gap-4 relative justify-center items-center">
    <!--div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">Gestion</div-->
    <div class="text-xl tracking-tight leading-tight text-center p-2">{{t("TUTO.6")}}</div>
    <!--button mat-icon-button class="btn btn-icon absolute top-0 right-0" (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button-->
  <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
    <button mat-raised-button color="" class="w-min"(click)="close(false)">
      {{t("fermer")}}
    </button>
    <button mat-raised-button color="primary" class="w-min"(click)="nextStep()">
      {{t("suivant")}}
    </button>
  </div>
</div>
</div>
}

@if (currentStep===4) {
  <div class="tutorial-popup bg-card rounded-b-xl p-5 z-10 absolute top-0">
    <div class="tutorial-popup-content flex flex-col gap-4 relative justify-center items-center">
    <!--div class="text-2xl font-bold tracking-tight leading-tight text-center p-2">Gestion</div-->
    <div class="text-xl tracking-tight leading-tight text-center p-2">{{t("TUTO.7")}}</div>
    <!--button mat-icon-button class="btn btn-icon absolute top-0 right-0" (click)="close(false)">
    <mat-icon>close</mat-icon>
  </button-->
  <div class="tutorial-popup-buttons w-full flex flex-row gap-4 justify-end items-center">
    <button mat-raised-button color="primary" class="w-min"(click)="close(false)">
      {{t("fermer")}}
    </button>
    <!--button mat-raised-button color="primary" class="w-min"(click)="nextStep()">
    Suivant
  </button-->
</div>
</div>
</div>
}




</div>