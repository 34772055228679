
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }

  public saveData(key: string, value: string) {
    //  console.log(value);
    localStorage.setItem(key.toLocaleLowerCase(), value);
  }

  public getData(key: string, defaultvalue: string) {

    let value = localStorage.getItem(key.toLocaleLowerCase());
    if (value == null)
      return defaultvalue;
    else
      return value;
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public setNewNavLink(keylink: string, val: string) {
    localStorage.setItem(keylink, val);
  }

  public getNavLink(keylink: string, defaultlink: string) {
    let value = localStorage.getItem(keylink);
    if (value == null)
      return defaultlink;
    else
      return value;
  }

  public getSceneFor(keylink: string): string {
    let value = localStorage.getItem("last-scene-" + keylink);
    if (value == null)
      return undefined;
    else
      return value;
  }

  public setSceneFor(keylink: string, val: string) {
    localStorage.setItem("last-scene-" + keylink, val);
  }
}