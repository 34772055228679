import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
}
)
export class MyNavigationService {

    title: Subject<string> = new Subject<string>();

  constructor() 
  { 
  }

    setTitle(title: string) {
        this.title.next(title);
    }

}