//import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { TranslocoModule } from '@ngneat/transloco';
import { SubscriptionType } from 'app/models/subscription.model';
import { DatabaseService } from 'app/services/database.service';
import { TutorialService } from 'app/services/tutorial.service';
import { SharedModule } from 'app/shared/shared.module';
import { Subject } from 'rxjs';
import { LanguagesComponent } from '../common/languages/languages.component';
import { MessagesComponent } from '../common/messages/messages.component';
import { SearchComponent } from '../common/search/search.component';
import { ShortcutsComponent } from '../common/shortcuts/shortcuts.component';
import { UserComponent } from '../common/user/user.component';


@Component({
    selector: 'tutorial',
    templateUrl: './tutorial.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [
        //HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        SharedModule,
        TranslocoModule,
            ]
})
export class TutorialComponent implements OnInit, OnDestroy
{
    @ViewChild('avatarImg') avatarImg : ElementRef;
    checked = false;
    currentStep = -1;
    maxStep = 4;
    elements : HTMLCollectionOf<Element>;
    elementsCopied = [];

    shadow = true;

    avatar = false;

    subscription;
    
    /**
     * Constructor
     */
    constructor(
        private _databaseService : DatabaseService,
        private _tutorialService : TutorialService,
        private _router : Router
    )
    {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.subscription = this._tutorialService.tutorialSubject.subscribe((tutorial) => {
            if(tutorial === 'nextStep')
            {
                this.nextStep();
            }
        });

    }

    ngOnDestroy(): void {
        if(this.subscription)
        {
            this.subscription.unsubscribe();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
   close(response : boolean)
    {
        if(!response)
        {
            if(this.checked)
            {
                this._databaseService.addDoNotShow('tutorial');
            }
            else
            {
                this._databaseService.hide('tutorial');
            }
            return;
        }
        this._router.navigate(['pages/content']);
        this.nextStep();
    }


    async nextStep()
    {

        this.shadow= true;
        const parent = document.getElementById('tuto-parent');
        if(this.elements !== undefined && this.elements.length > 0)
        {
            parent.innerHTML = '';
            for(let i = 0; i < this.elements.length; i++)
            {
                this.elements[i].classList.remove('tutorial-highlight');
                this.elements[i].removeEventListener('click', () => this.nextStep());
            }
        }
        this.currentStep++;

        if(this.currentStep === 3 && this._databaseService.user.Stripe.Type === SubscriptionType.Pro)
        {
            this.nextStep();
            return;
        }
        if(this.currentStep === 2 && this._databaseService.user.Stripe.Type !== SubscriptionType.Pro)
        {
            this.nextStep();
            return;
        }

        const toScroll = document.getElementsByClassName('step-scroll-'+this.currentStep);
        for(let i = 0; i < toScroll.length; i++)
        {
            toScroll[i].scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
        this.avatar = false;
        if(toScroll[0] !== undefined)
        {
            this.avatar = true;
            const rect = toScroll[0].getBoundingClientRect();
            this.avatarImg.nativeElement.setAttribute('style', 'width : auto; height : 240px; top : '+ rect.top+'px; left : '+rect.right+'px;');
        }


        this.elements = document.getElementsByClassName('step-'+this.currentStep);

        const length = this.elements.length;

        for(let i = 0; i < length; i++)
        {
            this.shadow = false;
            const rect = this.elements[i].getBoundingClientRect();
            const copied = document.createElement('div');
            parent.appendChild(copied);
            //copied.innerHTML = this.elements[i].outerHTML;
            copied.setAttribute('id', 'tuto-'+i);
            copied.classList.remove('step-'+this.currentStep);
            copied.setAttribute('style', 'width : '+rect.width+'px; height : '+rect.height+'px; top : '+rect.top+'px; left : '+rect.left+'px; background-color: rgba(0, 0, 0, 0)!important; box-shadow: 0 0 0 99999px rgba(0, 0, 0, .8);');


            copied.classList.add('tutorial-highlight','flex','flex-row','justify-center','rounded','bg-card');
            //copied.classList.add('absolute');
            /*copied.style.width = rect.width + 'px';
            copied.style.height = rect.height + 'px';
            copied.style.top = rect.top + 'px';
            copied.style.left = rect.left + 'px';*/
            const el = (this.elements[i] as HTMLElement);
            copied.addEventListener('click', () => {this.nextStep(); el.click();});
            //copied.addEventListener('click', () => this.nextStep());
            this.elementsCopied.push(copied);
            //this.elements[i].classList.add('tutorial-highlight');
            //this.elements[i].addEventListener('click', () => this.nextStep());
        }


        if(this.maxStep <= this.currentStep && (this.elements===undefined || this.elements.length == 0))
        {
            if(this.checked)
            {
                this._databaseService.addDoNotShow('tutorial');
            }
            else
            {
                this._databaseService.hide('tutorial');
            }
        }
    }

}
