import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'Admin',
        title: "ADMINISTRATION",
        icon: 'supervised_user_circle',
        type: 'aside',
         link : '/admin-register'

    },
    {
        id   : 'content',
        title: 'Bibliothèque',
        type : 'basic',
        icon : 'photo_library',
        link : '/pages/content'
    },
     {
        id: 'diffusion',
        title: "DIFFUSION",
        icon: 'computer',
        type: 'aside',
        link : '/pages/scenarios'

    },

    {
        id: 'gestion',
        title: "GESTION",
        icon: 'group',
        type: 'aside',
        children: [
            {
                id   : 'pairing',
                title: 'Mes Appareils',
                type : 'basic',
                icon : 'devices',
                link : '/pages/match'
            },
            {
                id   : 'projectsV2',
                title: 'Projet',
                type : 'basic',
                icon : 'view_module',
                link : '/pages/projectsV2'
            },
            {
                id   : 'devices',
                title: 'Mes box',
                type : 'basic',
                icon : 'work_outline',
                link : '/pages/devices'
            },
            {
                id   : 'interface',
                title: 'Interface',
                type : 'basic',
                icon : 'image',
                link : '/pages/interface'
            },
            {
                id   : 'myusers',
                title: 'Mes utilisateurs',
                type : 'basic',
                icon : 'group',
                link : '/pages/myusers'
            },
           
        ]
    },
   
    {
        id: 'report',
        title: 'Rapport',
        icon : 'group',
        type: 'aside',
        children: [
            {
                id   : 'stats',
                title: 'Statistiques',
                type : 'basic',
                icon : 'analytics',
                link  : '/pages/report'
            },
            {
                id   : 'form',
                title: 'Formulaire',
                type : 'basic',
                icon : 'table_view',
                link : '/pages/form'
            }
        ]
    },
    {
        id: 'template',
        title: 'TEMPLATE',
        icon : 'perm_media',
        type: 'aside',
        children: [
            {
                id   : 'content-template',
                title: 'Content Template',
                type : 'basic',
                icon : 'photo_library',
                link : '/pages/default-content'
            },
            {
                id   : 'story-template',
                title: 'Story Template',
                type : 'basic',
                icon : 'computer',
                link  : '/pages/story-template'
            }
        ]
    },
    {
        id   : 'pricing',
        title: 'Mon abonnement',
        type : 'basic',
        icon : 'card_membership',
        link : '/pages/pricing'
    },
    {
        id   : 'wiki',
        title: 'Documentation',
        type : 'basic',
        icon : 'support',
        externalLink: true,
        target: '_blank',
        link : 'https://www.explorations360.com/creation-experience-360-astuces-ressources/'
    },
    {
        id   : 'support',
        title: 'Support',
        type : 'basic',
        icon : 'support',
        externalLink: true,
        target: '_blank',
        link : 'https://dol.explorations360.tools/public/ticket/'
    }

];
