import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuardService } from './services/admin-guard.service';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';

// prettier-ignore
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: '/sign-in'},

    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'pages/content'},

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
   // {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboards/project'},

    // Auth routes for guests
    {
        path: '',
        //canActivate: [NoAuthGuard],
        //canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            //{path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            //{path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
           // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/main/pages/authentication/login/login.routes')},
            {path: 'register', loadChildren: () => import('app/main/pages/authentication/register/register.routes')},
            {path: 'share', loadChildren: () => import('app/main/pages/share/share.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/main/pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
            {path: '', loadChildren: () => import('app/main/pages/viewer/lock/lock.module').then(m => m.LockModule)},
            {path: 'pages/auth/reset-password', loadChildren: () => import('app/main/pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'compact'
        },
        children: [
            {path: 'pages', children: [
                // Activities
                {path: 'content', loadChildren: () => import('app/main/pages/content/content.routes')},
                {path: 'default-content', loadChildren: () => import('app/main/pages/default-content/default-content.routes')},
                {path:'story-template', loadChildren: () => import('app/main/pages/scenario/story-template/story-template.routes')},
                {path: 'scenarios', loadChildren: () => import('app/main/pages/scenario/scenarios.routes')},
                {path: 'interface', loadChildren: () => import('app/main/pages/interface/interface.routes')},
                {path: 'projectsV2', loadChildren: () => import('app/main/pages/projectsV2/projectsV2.routes')},
                {path: 'devices', loadChildren: () => import('app/main/pages/devices/devices.routes')},
                {path: 'myusers', loadChildren: () => import('app/main/pages/myusers/myusers.routes')},
                {path: 'profile', loadChildren: () => import('app/main/pages/profile/profile.routes')},
                {path: 'form', loadChildren: () => import('app/main/pages/form/form.routes')},
                {path: 'pricing', loadChildren: () => import('app/main/pages/pricing-modern/pricing-modern.routes')},
                {path: 'share', loadChildren: () => import('app/main/pages/share/share.routes')},
                {path: 'match', loadChildren: () => import('app/main/pages/pairing/pairing.routes')},
                {path: 'report', loadChildren: () => import('app/main/pages/statistics/statistics.routes')},
                //{path: 'pricing', loadChildren: () => import('app/main/pages/content/content.module').then(m => m.ContentModule)},
            ]},

        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: EmptyLayoutComponent,
        data: {
            layout: 'compact'
        },
        children: [
            {path: 'scenario-editor', children: [
                { path: '', loadChildren: () => import('app/main/pages/scenario/scenario-editor/scenario-editor.routes')}
            ]}
        ]
    },

    // Landing routes
  /*  {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },*/

    // Admin routes
    {
        path       : '',
        canActivate: [AdminGuardService],
        canActivateChild: [AdminGuardService],
        component: LayoutComponent,
        data: {
            layout: 'compact'
        },
        children   : [
                {path: 'admin-register', loadChildren: () => import('app/main/pages/admin/register/admin-register.routes')}
        ]
    },

];