<!-- Notifications toggle -->
<button
  mat-icon-button
  (click)="openPanel()"
  #notificationsOrigin>
  @if (unreadCount > 0) {
    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary text-on-primary text-xs font-medium">
        {{unreadCount}}
      </span>
    </span>
  }
  <mat-icon>notifications</mat-icon>
</button>

<!-- Notifications Animation -->
<div class="fixed bg-black bg-opacity-50 rounded-2xl p-10 flex flex-row items-center justify-center" style="opacity: 0;" [@notifAnim]="trigger"  (@notifAnim.done)="trigger = false;">
  <mat-icon color="primary" style="width: 40px; height: 40px; line-height: 40px; font-size: 40px; opacity: 1;">notifications</mat-icon>
</div>

<!-- Notifications panel -->
<ng-template #notificationsPanel *transloco="let t">

  <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-2xl">

    <!-- Header -->
    <div class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-accent-400 text-on-accent">
      <div class="sm:hidden -ml-1 mr-3">
        <button
          mat-icon-button
          color="primary"
          (click)="closePanel()">
          <mat-icon
            class="icon-size-5 text-current"
          >close</mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">{{t("NOTIF.1")}}</div>
      <div class="ml-auto">
        <button
          mat-icon-button
          color="primary"
          [matTooltip]="'Mark all as read'"
          [disabled]="unreadCount === 0"
          (click)="markAllAsRead()">
          <mat-icon
            class="icon-size-5 text-current"
          >mark_email_read</mat-icon>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
      <!-- Notifications -->
      @for (notification of notifications; track trackByFn($index, notification)) {
        <div
          class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
          [ngClass]="{'unread': !notification.read}">
          <!-- Notification with a link -->
          @if (notification.link) {
            <!-- Normal links -->
            @if (!notification.useRouter) {
              <a
                class="flex flex-auto py-5 pl-6 cursor-pointer"
                [href]="notification.link">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            }
            <!-- Router links -->
            @if (notification.useRouter) {
              <a
                class="flex flex-auto py-5 pl-6 cursor-pointer"
                [routerLink]="notification.link">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            }
          }
          <!-- Notification without a link -->
          @if (!notification.link) {
            <div class="flex flex-auto py-5 pl-6">
              <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
          }
          <!-- Actions -->
          <div class="relative flex flex-col my-5 mr-6 ml-2">
            <!-- Indicator -->
            <button
              class="w-6 h-6 min-h-6"
              mat-icon-button
              color="primary"
              (click)="toggleRead(notification)"
              [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'">
              <span
                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.read,
                                            'bg-primary': !notification.read}"></span>
            </button>
            <!-- Remove -->
            <button
              class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
              mat-icon-button
              color="primary"
              (click)="delete(notification)"
              [matTooltip]="'Remove'">
              <mat-icon
                class="icon-size-4"
              >close</mat-icon>
            </button>
          </div>
        </div>
        <!-- Notification content template -->
        <ng-template #notificationContent>
          <!-- Icon -->
          @if (notification.icon && !notification.image) {
            <div class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
              <mat-icon
                class="icon-size-5"
                >{{notification.icon}}
              </mat-icon>
            </div>
          }
          <!-- Image -->
          @if (notification.image) {
            <img
              class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
              [src]="notification.image"
              [alt]="'Notification image'">
            }
            <!-- Title, description & time -->
            <div class="flex flex-col flex-auto">
              @if (notification.title) {
                <div
                  class="font-semibold line-clamp-1"
                [innerHTML]="notification.title"></div>
              }
              @if (notification.description) {
                <div
                  class="line-clamp-2"
                [innerHTML]="notification.description"></div>
              }
              <div class="mt-2 text-sm leading-none text-secondary">
                {{notification.time | date:'dd MMM, H:mm'}}
              </div>
            </div>
          </ng-template>
        }

        <!-- No notifications -->
        @if (!notifications || !notifications.length) {
          <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
            <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
              <mat-icon
                class="text-primary-500-700"
              >notifications</mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">{{t("NOTIF.2")}}</div>
          </div>
        }

      </div>

    </div>

  </ng-template>
